import React, { useState } from 'react';
import { FaMicrochip, FaCode, FaUsers, FaGamepad, FaLightbulb, FaGraduationCap, FaHome, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import playardButton from '../Assets/products/Playard-Button.jpg';
import playardRetroV2 from '../Assets/products/Playard-Retro-V2.jpg';
import playardRetroV1 from '../Assets/products/Playard-Retro-V1.jpg';


const PixelArtIcon = ({ icon: Icon }) => (
  <div className="w-16 h-16 bg-gray-800 flex items-center justify-center text-yellow-400 text-3xl">
    <Icon />
  </div>
);

const ServiceCard = ({ title, description, icon }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
    <PixelArtIcon icon={icon} />
    <h3 className="text-xl font-bold mt-4 mb-2 text-yellow-400">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const PortfolioItem = ({ title, description, image, onClick }) => (
  <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
    <img 
      src={image} 
      alt={title} 
      className="w-full h-48 object-cover rounded-lg mb-4 cursor-pointer" 
      onClick={() => onClick(image)}
    />
    <h3 className="text-xl font-bold mb-2 text-yellow-400">{title}</h3>
    <p className="text-gray-300">{description}</p>
  </div>
);

const ImageModal = ({ image, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
    <div className="relative max-w-4xl max-h-full">
      <img src={image} alt="Full size" className="max-w-full max-h-[90vh] object-contain" />
      <button 
        onClick={onClose} 
        className="absolute top-4 right-4 text-white text-2xl hover:text-yellow-400"
      >
        <FaTimes />
      </button>
    </div>
  </div>
);

const ServicesPage = () => {
  const [activeTab, setActiveTab] = useState('services');
  const [selectedImage, setSelectedImage] = useState(null);

  const services = [
    {
        title: "Hardware Development",
        description: "Custom design and prototyping of retro-inspired gaming hardware and IoT devices.",
        icon: FaMicrochip
      },
      {
        title: "Software Development",
        description: "Creation of firmware, games, and applications tailored for our unique hardware platforms.",
        icon: FaCode
      },
      {
        title: "Community-Driven Innovation",
        description: "Collaborative product development incorporating user feedback and contributions.",
        icon: FaUsers
      },
      {
        title: "Game Design Workshops",
        description: "Interactive sessions teaching game design principles for retro-style games.",
        icon: FaGamepad
      },
      {
        title: "IoT Consulting",
        description: "Expert advice on integrating IoT capabilities into products and systems.",
        icon: FaLightbulb
      },
      {
        title: "Educational Program Development",
        description: "Creation of tech-focused curricula for schools and coding bootcamps.",
        icon: FaGraduationCap
      }
    ];

  const portfolioItems = [
    {
      title: "Playard Retro V1",
      description: "Our flagship retro gaming console, featuring dual screens and multiple controllers.",
      image: playardRetroV1
    },
    {
      title: "IoT Smart Button",
      description: "A versatile, WiFi-enabled button for various IoT applications.",
      image: playardButton
    },
    {
      title: "Custom Game Development",
      description: "A collection of retro-style games developed for our platforms.",
      image: playardRetroV2
    }
  ];

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="bg-gray-900 min-h-screen text-white p-8">
      <Link 
        to="/" 
        className="inline-block mb-8 bg-yellow-400 text-gray-900 px-4 py-2 rounded-full hover:bg-yellow-500 transition duration-300"
      >
        <FaHome className="inline mr-2" />
        Back to Home
      </Link>
      <h1 className="text-4xl font-bold text-center mb-8 text-yellow-400">Our Services</h1>
      
      <div className="flex justify-center mb-8">
        <button 
          className={`px-4 py-2 mr-4 rounded ${activeTab === 'services' ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800'}`}
          onClick={() => setActiveTab('services')}
        >
          Services
        </button>
        <button 
          className={`px-4 py-2 rounded ${activeTab === 'portfolio' ? 'bg-yellow-400 text-gray-900' : 'bg-gray-800'}`}
          onClick={() => setActiveTab('portfolio')}
        >
          Portfolio
        </button>
      </div>

      {activeTab === 'services' && (
        <div>
          <p className="text-center mb-8 max-w-2xl mx-auto">
            At Playard, we offer a range of services focused on retro-inspired technology and innovative IoT solutions. Our expertise spans hardware design, software development, and educational initiatives.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service) => (
              <ServiceCard key={service.title} {...service} />
            ))}
          </div>
        </div>
      )}

      {activeTab === 'portfolio' && (
        <div>
          <p className="text-center mb-8 max-w-2xl mx-auto">
            Explore our portfolio of successful projects and products. Each item showcases our commitment to quality, innovation, and user-centric design.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {portfolioItems.map((item) => (
              <PortfolioItem key={item.title} {...item} onClick={handleImageClick} />
            ))}
          </div>
        </div>
      )}

      <div className="mt-16 text-center">
        <h2 className="text-2xl font-bold mb-4 text-yellow-400">Ready to Bring Your Ideas to Life?</h2>
        <p className="mb-8">Contact us today to discuss your project or learn more about our services.</p>
        <a 
          href="/contact" 
          className="bg-yellow-400 text-gray-900 px-6 py-3 rounded-full hover:bg-yellow-500 transition duration-300"
        >
          Get in Touch
        </a>
      </div>

      {selectedImage && (
        <ImageModal image={selectedImage} onClose={() => setSelectedImage(null)} />
      )}
    </div>
  );
};

export default ServicesPage;