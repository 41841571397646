import React, { useState } from 'react';

const PixelArtIcon = ({ children }) => (
  <div className="w-16 h-16 bg-gray-800 flex items-center justify-center text-3xl">
    {children}
  </div>
);

const KickstarterProject = ({ title, description, icon, launchDate }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="bg-gray-800 p-4 rounded-lg cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
      <div className="flex items-center mb-2">
        <PixelArtIcon>{icon}</PixelArtIcon>
        <h3 className="ml-4 text-xl font-bold text-yellow-400">{title}</h3>
      </div>
      {isExpanded && (
        <div className="mt-2">
          <p className="text-gray-300 mb-2">{description}</p>
          <p className="text-sm text-green-400">Launching: {launchDate}</p>
        </div>
      )}
    </div>
  );
};

const UpcomingKickstarters = () => {
  const projects = [
    {
      title: "Playard Retro V3",
      description: "The next evolution of our retro gaming console, featuring enhanced graphics and expanded game library.",
      icon: "🎮",
      launchDate: "August 2024"
    },
    {
      title: "Playard IoT Kit",
      description: "A comprehensive IoT development kit, including our Playard Button and new sensors.",
      icon: "📡",
      launchDate: "September 2024"
    },
    {
      title: "Playard Educational Bundle",
      description: "A complete package for schools and coding clubs, featuring our hardware and custom curriculum.",
      icon: "🏫",
      launchDate: "October 2024"
    }
  ];

  return (
    <section className="bg-gray-900 p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center text-yellow-400">Upcoming Kickstarter Projects</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {projects.map((project) => (
          <KickstarterProject key={project.title} {...project} />
        ))}
      </div>
      <div className="mt-8 text-center">
        <a 
          href="#" 
          className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600 transition duration-300"
        >
          Get Notified
        </a>
      </div>
    </section>
  );
};

export default UpcomingKickstarters;