import React, { useState } from "react";
import { Link } from 'react-router-dom';
import playardRetro from "../Assets/playard_logo_green.svg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-playard-dark-lighter text-white py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center relative overflow-hidden">
            <div className="relative w-24 h-24"> {/* Increased size */}
              <img src={playardRetro} alt="Playard Logo" className="w-full h-full mr-4" /> {/* Adjusted to fill container */}
              <div className="absolute top-0 -inset-full h-full w-1/2 z-5 block transform -skew-x-12 bg-gradient-to-r from-transparent to-white opacity-40 animate-shine-continuous" />
            </div>
            {/* Removed the text span as it was empty */}
          </Link>
          
          <div className="hidden md:flex space-x-6"> {/* Increased space between nav items */}
            <NavLink to="/about">About</NavLink>
            <NavLink to="/documentation">Documentation</NavLink>
            <NavLink to="/roadmap">Feature Roadmap</NavLink>
            <NavLink to="/shop">Shop</NavLink>
            <NavLink to="/services">Services</NavLink>
          </div>
          
          <button 
            className="md:hidden text-white"
            onClick={() => setIsOpen(!isOpen)}
          >
            <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
        
        {isOpen && (
          <div className="mt-4 md:hidden">
            <NavLink to="/about" mobile>About</NavLink>
            <NavLink to="/documentation" mobile>Documentation</NavLink>
            <NavLink to="/roadmap" mobile>Feature Roadmap</NavLink>
            <NavLink to="/services" mobile>Services</NavLink>
            <NavLink to="/shop" mobile>Shop</NavLink>
          </div>
        )}
      </div>
    </header>
  );
};

const NavLink = ({ to, children, mobile }) => (
  <Link 
    to={to} 
    className={`text-white hover:text-playard-yellow transition ${mobile ? 'block py-2' : ''}`}
  >
    {children}
  </Link>
);

export default Header;