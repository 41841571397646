import React from 'react'
import Header from '../Layouts/Header'
import Footer from '../Layouts/Footer'

const MakeaGame = () => {
  return (
    <div>

    <Header></Header>
    <Footer></Footer>

    </div>
  )
}

export default MakeaGame