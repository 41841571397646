import React, { useState } from 'react';

const PixelArtProduct = ({ name, pixelArt, onClick }) => (
  <div className="cursor-pointer hover:opacity-80 transition-opacity" onClick={onClick}>
    <div className="bg-gray-800 p-4 rounded-lg">
      {pixelArt}
    </div>
    <p className="text-center mt-2 text-sm font-bold">{name}</p>
  </div>
);

const ProductShowcase = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = [
    {
      name: "Playard Retro V1",
      pixelArt: (
        <div className="w-32 h-32 bg-gray-900 relative">
          <div className="absolute top-1/4 left-1/4 w-16 h-8 bg-blue-200"></div>
          <div className="absolute bottom-1/4 right-1/4 w-8 h-4 bg-yellow-200"></div>
          <div className="absolute bottom-1/4 left-1/4 w-6 h-6 bg-red-500 rounded-full"></div>
          <div className="absolute top-1/4 right-1/4 w-4 h-1 bg-green-500"></div>
        </div>
      ),
      description: "A 2 microcontroller-based retro game console with RP2040 and ESP32S3. Features 2 screens (LCD and OLED), 1 joystick, 1 SD card slot, 2 Type-C plugs for programming, and WiFi capability.",
    },
    {
      name: "Playard Retro V2",
      pixelArt: (
        <div className="w-32 h-32 bg-gray-900 relative">
          <div className="absolute top-1/4 left-1/4 w-16 h-10 bg-purple-200"></div>
          <div className="absolute bottom-1/4 left-1/4 w-16 h-6 bg-gray-400"></div>
          <div className="absolute top-3/4 right-1/4 w-8 h-2 bg-green-500"></div>
        </div>
      ),
      description: "A Raspberry Pi CM4 retro game console with an ICN9150 touchpad. Features a PlayIO port for custom sensor and controller development.",
    },
    {
      name: "Playard Button",
      pixelArt: (
        <div className="w-32 h-32 bg-gray-900 flex items-center justify-center">
          <div className="w-20 h-20 bg-red-500 rounded-full border-4 border-gray-300"></div>
        </div>
      ),
      description: "A simple button with WiFi capabilities, perfect for IoT projects and creative applications.",
    },
  ];

  return (
    <div className="bg-white-100 p-8 rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold mb-6 text-center">Playard Products</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mb-8">
        {products.map((product) => (
          <PixelArtProduct
            key={product.name}
            name={product.name}
            pixelArt={product.pixelArt}
            onClick={() => setSelectedProduct(product)}
          />
        ))}
      </div>
      {selectedProduct && (
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h3 className="text-2xl font-bold mb-4">{selectedProduct.name}</h3>
          <div className="flex flex-col md:flex-row items-center mb-4">
            <div className="md:w-1/3 mb-4 md:mb-0">
              {selectedProduct.pixelArt}
            </div>
            <p className="md:w-2/3 md:pl-6 text-gray-700">{selectedProduct.description}</p>
          </div>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            onClick={() => setSelectedProduct(null)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default ProductShowcase;